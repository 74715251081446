<template>
  <div class="add-patient-to-program-form ">
    <div class="row">
      <div class="col-xl-10 col-lg-12">
        <h3 v-if="!editMode">
          {{ $t('addPatientToProgram') }}
        </h3>
        <p
          v-if="!editMode"
          class="title"
        >
          {{ patient.first_name }}
        </p>
        <form @submit.prevent="gatherDataAndCreate">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>{{ $t('episodeId') }}</label>
                <input
                  v-model="form.episode_id"
                  class="form-control"
                >
              </div>
            </div>
          </div>
          <div
            v-if="!editMode"
            class="row"
          >
            <div class="col-10">
              <div class="form-group">
                <label>{{ $t('programName') }}</label>
                <multiselect
                  v-model="selectedProgram"
                  v-validate="'required'"
                  :options="filteredPrograms"
                  :show-labels="false"
                  :hide-selected="false"
                  :placeholder="$t('select')"
                  label="full_name"
                  track-by="id"
                  :allow-empty="false"
                  :preserve-search="currentBrowser.isExplorer"
                  :data-vv-as="$t('programName')"
                  name="program"
                  @select="assignProgram"
                >
                  <template slot="caret">
                    <i class="fal fa-search" />
                  </template>
                  <template slot="noResult">
                    {{ $t('noResultsFound') }}
                  </template>
                </multiselect>
                <input
                  v-model="hasProgramSelected"
                  v-validate="'required'"
                  type="hidden"
                  name="has_program_selected"
                  :data-vv-as="$t('program')"
                >
                <span
                  v-show="errors.has('has_program_selected')"
                  class="error"
                >{{ errors.first('has_program_selected') }}</span>
              </div>
            </div>
          </div>
          <div class="row clinicians">
            <div class="col-10">
              <div class="form-group">
                <label for="patientClinician">{{ $t('programClinician') }}</label>
                <clinicians-teams-selector
                  ref="cliniciansTeamsSelector"
                  v-model="selectedClinicians"
                  name="clinicians_teams"
                  :teams-enabled="true"
                  :data-vv-as="$t('programClinician')"
                />
                <input
                  v-model="hasCliniciansSelected"
                  v-validate="'required'"
                  type="hidden"
                  name="has_clinician_selected"
                  :data-vv-as="$t('programClinician')"
                >
                <span
                  v-show="errors.has('has_clinician_selected')"
                  class="error"
                >{{ errors.first('has_clinician_selected') }}</span>
              </div>
            </div>
          </div>
          <div class="row admission-intake">
            <div class="col-6">
              <div class="form-group">
                <label>{{ $t('admissionDate') }}</label>
                <datepicker
                  v-model="form.admission_date"
                  class="form-control"
                  format="MMM dd, yyyy"
                />
                <input
                  v-model="form.admission_date"
                  v-validate="'required'"
                  type="hidden"
                  name="admission_date"
                  :data-vv-as="$t('admissionDate')"
                >
                <span
                  v-show="errors.has('admission_date')"
                  class="error"
                >{{ errors.first('admission_date') }}</span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>{{ $t('intakeDate') }}</label>
                <datepicker
                  v-model="form.intake_date"
                  class="form-control"
                  format="MMM dd, yyyy"
                />
                <input
                  v-model="form.intake_date"
                  type="hidden"
                  name="intake_date"
                >
              </div>
            </div>
          </div>
          <div
            v-if="editMode"
            class="row disposition-termination"
          >
            <div class="col-6">
              <div class="form-group">
                <label>{{ $t('dispositionStatus') }}</label>
                <select
                  v-model="form.disposition_status"
                  v-validate="dispositionStatusValidator"
                  class="form-control"
                  name="disposition_status"
                  :data-vv-as="$t('dispositionStatus')"
                >
                  <option
                    v-for="item in dispositionStatus"
                    :key="item.key"
                    :value="item.key"
                  >
                    {{ item.label }}
                  </option>
                </select>
                <span
                  v-show="errors.has('disposition_status')"
                  class="error"
                >{{ errors.first('disposition_status') }}</span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>{{ $t('dischargeDate') }}</label>
                <datepicker
                  v-model="form.termination_date"
                  class="form-control"
                  format="MMM dd, yyyy"
                  @selected="validateDischargeDate"
                />
                <input
                  v-model="form.termination_date"
                  v-validate="dischargeDateValidator"
                  type="hidden"
                  name="termination_date"
                  :data-vv-as="$t('dischargeDate')"
                >
                <span
                  v-show="errors.has('termination_date')"
                  class="error"
                >{{ errors.first('termination_date') }}</span>
              </div>
            </div>
          </div>

          <div v-if="!editMode || (editMode && schedulingOptions.measures_assigned)">
            <ScheduleMeasure
              ref="scheduleMeasure"
              context="program"
              :measure-list-title="$t('ProgramSpecificMeasures')"
              :compact-view="true"
              :is-disabled="editMode"
              :available-measures="schedulingOptions.available_measures"
              :show-measures-assigned="true"
              :frequency-options="schedulingOptions.frequency_options"
              :measures-assigned="assignedMeasures"
              :measures-enqueued="measuresEnqueued"
              :show-visit-type="true"
              @soft-delete="deleteEnqueued"
              @schedule="enqueueMeasure"
            />
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col buttons">
        <div v-if="!editMode">
          <button
            type="button"
            :disabled="!continueButtonEnabled"
            class="btn btn-outline-secondary"
            @click="createAndAddAnother()"
          >
            {{ $t('addAnotherProgram') }}
          </button>
          <button
            type="button"
            :disabled="!continueButtonEnabled"
            class="btn btn-secondary"
            @click="gatherDataAndCreate()"
          >
            {{ $t('done') }}
          </button>
        </div>
        <div v-else>
          <button
            type="button"
            :disabled="!continueButtonEnabled"
            class="btn btn-outline-secondary"
            @click="$emit('cancel')"
          >
            {{ $t('cancel') }}
          </button>
          <button
            type="button"
            :disabled="!continueButtonEnabled"
            class="btn btn-secondary"
            @click="updateAssignment()"
          >
            {{ $t('confirm') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CliniciansTeamsSelector from '@/components/common/CliniciansTeamsSelector'
import ScheduleMeasure from '@/components/common/schedule/ScheduleMeasure'
import DispositionStatusData from '@/data/disposition-status-data'
import { componentRefreshSingleton } from '@/mixins/ComponentRefresh/ComponentRefreshSingleton'
import { ErrorsDictionary } from '@/mixins/ErrorsDictionary'
import { Helpers } from '@/mixins/Helpers'
import { PatientHelper } from '@/mixins/PatientHelper'
import { UsageTypeSettingService } from '@/mixins/settings/UsageTypeSettingService'
import { GET_ALL_CLINICIANS } from '@/store/modules/clinicians/constants.js'
import { ACTIONS, MUTATIONS } from '@/store/modules/programList/constants'
import { GET_PROGRAMS } from '@/store/modules/programs/constants.js'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import Datepicker from 'vuejs-datepicker'
import { mapGetters } from 'vuex'

const PRE_SELECTED_PROGRAM = 'programs/PRE_SELECTED_PROGRAM'

const initialData = (preSelectedProgramId) => {
  return {
    submitState: false,
    preSelectedProgramId: preSelectedProgramId,
    form: {
      intake_date: '',
      admission_date: moment().format(),
      program_measures: [],
      disposition_status: 1,
      episode_id: ''
    },
    selectedProgram: {},
    dispositionStatus: DispositionStatusData,
    selectedClinicians: {
      clinicians: [],
      teams: []
    },
    hasCliniciansSelected: null,
    hasProgramSelected: null,
    customMeasuresEnqueued: [],
    loaded: false
  }
}

export default {
  name: 'ClinicianAddPatientToProgram',
  components: {
    Multiselect,
    Datepicker,
    CliniciansTeamsSelector,
    ScheduleMeasure
  },
  mixins: [Helpers, ErrorsDictionary, PatientHelper],
  props: ['program', 'patientId'],
  data () {
    return initialData(this.$store.getters.preSelectedProgramId)
  },
  computed: {
    ...mapGetters({
      homeTab: 'getHomeTab',
      patient: 'getSinglePatient',
      programs: 'programs',
      currentBrowser: 'getCurrentBrowser',
      types: 'getProgramTypes',
      clinicians: 'getClinicians',
      clinicianLogged: 'loggedInUser',
      schedulingOptions: 'getSchedulingOptions',
      patientPrograms: 'getSinglePatientPrograms',
      settings: 'generalSettings'
    }),
    computedForm () {
      return Object.assign({}, this.form)
    },
    editMode () {
      return !!this.program
    },
    measuresEnqueued () {
      return this.form.program_measures ? this.form.program_measures.concat(this.customMeasuresEnqueued) : []
    },
    filteredPrograms () {
      const programs = this.programs.filter(p => {
        const id = Number(p.id)
        if (this.programsAdded.indexOf(id) === -1) {
          return true
        }

        const exist = this.patientPrograms.filter(program => Number(program.program_id) === id && parseInt(program.discharged) === 0)
        return !exist.length
      })

      if (!this.form.type) {
        return programs
      }

      return programs.filter(ob => { return String(ob.type) === String(this.form.type) })
    },
    continueButtonEnabled () {
      if (this.submitState) {
        return false
      }
      return true
    },
    programsAdded () {
      return this.patientPrograms.map(o => Number(o.program_id))
    },
    dischargeDateValidator () {
      return this.computedForm.disposition_status !== 1 ? 'required' : ''
    },
    dispositionStatusValidator () {
      return this.computedForm.termination_date ? { is_not: 1 } : ''
    },
    assignedMeasures () {
      if (!this.editMode) {
        return []
      }
      return this.schedulingOptions.measures_assigned.filter((item) => parseInt(item.access_control_program_id) === parseInt(this.program.access_control_program_id))
    }
  },
  watch: {
    program () {
      this.initForm()
    },
    computedForm: {
      handler (n, o) {
        if (this.loaded) {
          this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', Object.keys(this.form).some(field => n[field] !== o[field]))
        }
      },
      deep: true
    },
    selectedProgram (n, o) {
      this.hasProgramSelected = null
      if (n) {
        this.hasProgramSelected = true
      }
    },
    selectedClinicians (n, o) {
      this.hasCliniciansSelected = null
      if (n) {
        this.hasCliniciansSelected = n.clinicians.length || n.teams.length ? true : null
      }

      if (n && n !== o) {
        this.form.clinicians = n.clinicians
        this.form.clinicians_teams = n.teams
      }
    }
  },
  created () {
    if (this.patient.status === 'inactive') {
      this.$toast.warning({ message: this.$t('willActivatePatient', { action: this.$t('addingToaProgram') }) })
    }
    this.$validator.localize('en', this.errorsDictionary)
    const loader = this.$loading.show()
    const accessControlId = this.patientId ? this.patientId : Number(this.$getDecodedId(this.$route.params.patientId))
    const programsPromise = this.$store.dispatch(GET_PROGRAMS)
    const cliniciansPromise = this.$store.dispatch(GET_ALL_CLINICIANS)
    const patientProgramsPromise = this.$store.dispatch('GET_SINGLE_PATIENT_PROGRAMS', accessControlId)

    Promise.all([programsPromise, cliniciansPromise, patientProgramsPromise]).then(() => {
      this.initForm().then(() => {
        this.getPatient(this.form.access_control_id).then(() => {
          const ccauid = this.patient.client_clinic_assignment_user_id
          if (ccauid) {
            this.$store.dispatch('GET_SCHEDULING_OPTIONS', { ccauId: ccauid, getTeamMeasures: true })
          }
        })

        if (this.preSelectedProgramId) {
          this.selectedProgram = this.getProgramById(this.preSelectedProgramId)
          this.assignProgram(this.selectedProgram)
        }
      }).finally(() => {
        loader.hide()
        this.loaded = true
      })
    })
  },
  destroyed () {
    this.$store.dispatch('RESET_SCHEDULING_OPTIONS')
    this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', false)
  },
  methods: {
    validateDischargeDate () {
      this.$nextTick().then(() => {
        this.$validator.validate('disposition_status')
        this.$validator.validate('termination_date')
      })
    },
    shouldDischargePatient (data) {
      const terminationChanged = data.oldData.termination_date !== data.newData.termination_date
      const dispositionChanged = data.oldData.disposition_status !== data.newData.disposition_status
      return data.newData.disposition_status !== 1 && (dispositionChanged || terminationChanged)
    },
    dischargePatient (data) {
      const params = {
        patient_id: this.patient.id,
        access_control_program_id: this.program.access_control_program_id,
        date: data.newData.termination_date,
        measures: [],
        disposition_status: data.newData.disposition_status,
        assignment_id: Number(this.$getDecodedId(this.$route.params.ccaId))
      }
      return this.$store.dispatch('DISCHARGE_FROM_PROGRAM', params)
    },
    executeRequest (data, isUpdate, addAnother) {
      this.submitState = true
      const loader = this.$loading.show()
      const action = isUpdate ? 'UPDATE_ASSIGNMENT' : 'CREATE_ASSIGNMENT'
      if (isUpdate) {
        data.patient_id = this.form.access_control_id
        data.access_control_program_id = this.program.access_control_program_id
      }
      this.$store.dispatch(action, data).then(response => {
        return response && response.status !== 200
          ? this.handleRequestError(response)
          : this.handleRequestSuccess(response, isUpdate, data, addAnother)
      }).catch((e) => {
        this.$handleApiError(e)
      }).finally(() => {
        loader.hide()
        this.submitState = false
      })
    },
    handleRequestError (httpResponse) {
      this.$handleApiError(httpResponse)
      Object.keys(httpResponse.data.errors).forEach(function (field) {
        this.$validator.errors.add(field, httpResponse.data.errors[field])
      })
    },
    handleRequestSuccess (httpResponse, isUpdate, data, addAnother) {
      this.$updatePatientOverview()
      this.tryToShowInvitationSentPop(httpResponse.data.invitation_sent)
      return isUpdate ? this.handleUpdateSuccess(data) : this.handleCreateSuccess(data, addAnother)
    },
    tryToShowInvitationSentPop (invitationSent) {
      return invitationSent ? this.$toast.success({ message: this.$t('invitationSent') }) : null
    },
    handleUpdateSuccess (data) {
      if (this.shouldDischargePatient(data)) {
        this.dischargePatient(data)
      }
      this.$toast.success({ message: this.$t('programDetailsUpdated') })
      this.$emit('cancel')
    },
    handleCreateSuccess (data, addAnother) {
      componentRefreshSingleton.refreshComponents()
      this.updatePatientProgramsView()
      this.$toast.success({ message: this.$t('patientAddedToProgram') })
      return addAnother ? this.addAnotherResetForm() : this.updateCurrentView(data)
    },
    updatePatientProgramsView () {
      const getLatestSessionData = this.$route.name === 'ClinicianAddPatientToProgram'
      this.$store.dispatch('GET_SINGLE_PATIENT_PROGRAMS', Number(this.$getDecodedId(this.$route.params.patientId))).then(() => {
        if (getLatestSessionData) {
          this.getRespondentLatestSession()
        }
      })
    },
    addAnotherResetForm () {
      this.hasCliniciansSelected = null
      this.resetForm()
      this.updateSchedulingOptions()
    },
    updateSchedulingOptions () {
      const ccauId = Number(this.$getDecodedId(this.$route.params.ccauId))
      if (ccauId) {
        this.$store.dispatch('GET_SCHEDULING_OPTIONS', { ccauId: ccauId })
      }
      return true
    },
    updateCurrentView (data) {
      const routeIsAdd = this.$route.name === 'ClinicianAddProgramToPatient'
      return routeIsAdd ? this.updateProgramListAfterCreate(data) : this.updateClinicianPatientPrograms(data)
    },
    updateClinicianPatientPrograms (data) {
      this.updateSchedulingOptions(data)
      this.$router.push({ name: 'ClinicianPatientPrograms', params: this.$route.params })
      return true
    },
    updateProgramListAfterCreate (data) {
      this.$store.dispatch(ACTIONS.getUpdatedPatientData, {
        patientId: this.form.access_control_id,
        programId: data.program_id,
        justAdded: true
      }).then((patientData) => {
        this.$store.commit(MUTATIONS.addJustAddedToActiveProgram, patientData)
      }).finally(() => {
        this.$router.push({ name: 'ClinicianHomepage', query: { homeTab: this.homeTab, justAdded: 1 } })
      })
    },
    validateFormAndExecuteRequest (data, isUpdate, addAnother) {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.$reactivateIfInactive(this.$store.getters.getActiveAssignment).then(() => {
            this.executeRequest(data, isUpdate, addAnother)
          })
        }
      })
    },
    updateAssignment () {
      const form = { ...this.form }
      const data = {
        newData: {
          admission_date: moment(form.admission_date).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
          termination_date: moment(form.termination_date).isValid() ? moment(form.termination_date).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss') : null,
          disposition_status: form.disposition_status,
          clinicians: this.selectedClinicians.clinicians,
          clinicians_teams: this.selectedClinicians.teams,
          episode_id: form.episode_id
        },
        oldData: form
      }

      if (form.intake_date) {
        data.newData.intake_date = moment(form.intake_date).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss')
      }
      this.validateFormAndExecuteRequest(data, true)
    },
    gatherDataAndCreate (addAnother) {
      this.$store.commit(PRE_SELECTED_PROGRAM, null)
      const data = { ...this.form }

      if (this.selectedClinicians) {
        data.clinicians = this.selectedClinicians.clinicians
        data.clinicians_teams = this.selectedClinicians.teams
      }

      if (data.intake_date) {
        data.intake_date = moment(data.intake_date).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss')
      }
      data.admission_date = moment(data.admission_date).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss')

      this.$refs.scheduleMeasure.scheduleMeasure().then((validMeasureForm) => {
        if (validMeasureForm) {
          data.schedules = this.getMeasuresToScheduleData(data)
          this.validateFormAndExecuteRequest(data, false, addAnother)
        }
      })
    },
    getMeasuresToScheduleData (programData) {
      return this.measuresEnqueued.map((obj) => {
        const dueDate = this.getMeasureNextDueDate(obj, programData)
        const usageTypeID = this.getMeasureUsageType(obj, dueDate, programData)
        return {
          measure_id: obj.id,
          frequency: obj.frequency,
          due_date: dueDate,
          client_clinic_assignment_user_id: this.patient.client_clinic_assignment_user_id,
          scheduling_type: parseInt(obj.scheduling_type),
          usage_type: usageTypeID,
          analytics_usage_type: usageTypeID // This value should be changed when implementing new feature in the future
        }
      })
    },
    /**
     * Get Measure Usage Type, for now follow same rules as usual.
     * This is likely to change in the future. #MeasureTagging.
     * Todo: Revisit this in 5.8 measure tagging.
     * @param scheduleObject
     * @param dueDate
     * @param programData
     * @returns {int}
     */
    getMeasureUsageType (scheduleObject, dueDate, programData) {
      const usageTypeSettingService = new UsageTypeSettingService()
      const usageTypeMap = usageTypeSettingService.getUsageTypeMap()
      const isAdmissionMeasure = parseInt(scheduleObject.day_of_week) === 100

      // When Measure Manually added, check on the Due date to determine if Admission or Midpoint.
      if (!scheduleObject.belongs_to_program || !isAdmissionMeasure) {
        return dueDate === programData.admission_date ? usageTypeMap.admission.id : usageTypeMap.midpoint.id
      }

      // When measure added by program configuration and weekday check on date.
      return isAdmissionMeasure ? usageTypeMap.admission.id : usageTypeMap.midpoint.id
    },
    getMeasureNextDueDate (obj, programData) {
      // Custom measure use its own due date.
      if (!obj.belongs_to_program) {
        return obj.due_date
      }

      // Admission measure use admission date
      if (parseInt(obj.day_of_week) === 100) {
        return programData.admission_date
      }

      const momentDate = this.calculateDateForNextSelectedDay(programData.admission_date, obj.day_of_week)
      return momentDate.format('YYYY-MM-DD HH:mm:ss')
    },
    calculateDateForNextSelectedDay (admissionDate, nextSchedulingDay) {
      const momentObj = moment(admissionDate)
      const admissionDay = momentObj.weekday()

      // if we haven't yet passed the day of the week that I need, then just give me this week's instance of that day
      if (Number(admissionDay) <= Number(nextSchedulingDay)) {
        const toAdd = parseInt(nextSchedulingDay) - parseInt(admissionDay)
        return momentObj.add(toAdd, 'days')
      }

      const toAdd = (parseInt(nextSchedulingDay) - parseInt(admissionDay)) + 7
      return momentObj.add(toAdd, 'days')
    },
    createAndAddAnother () {
      this.gatherDataAndCreate(true)
    },
    initForm () {
      return new Promise((resolve) => {
        // If program means its update
        if (this.program) {
          const params = { accessControlProgramId: this.program.access_control_program_id, patientId: this.patientId, assignmentId: Number(this.$getDecodedId(this.$route.params.ccaId)) }
          this.$store.dispatch('GET_SINGLE_PATIENT_PROGRAM', params).then(() => {
            const program = this.$store.getters.getSinglePatientProgram
            this.selectedClinicians = { clinicians: program.clinicians, teams: program.clinicians_teams }
            this.$refs.cliniciansTeamsSelector.setValues(this.selectedClinicians)
            this.selectedProgram = program.program
            this.form = {
              type: program.program.type,
              program_id: program.program_id,
              access_control_id: this.patientId,
              id: program.id,
              program_measures: [],
              intake_date: program.intake_date ? moment(program.intake_date).format() : null,
              admission_date: program.admission_date ? moment(program.admission_date).format() : null,
              disposition_status: program.disposition_status,
              termination_date: program.termination_date ? moment(program.termination_date).format() : null,
              clinicians: program.clinicians,
              clinicians_teams: program.clinicians_teams,
              episode_id: program.episode_id
            }
            resolve()
          })
        } else {
          this.form = {
            intake_date: '',
            admission_date: moment().format(),
            access_control_id: Number(this.$getDecodedId(this.$route.params.patientId)),
            program_measures: [],
            disposition_status: 1,
            clinicians: [],
            clinicians_teams: []
          }
          resolve()
        }
      })
    },
    getProgramById (id) {
      let program = null
      const programs = this.filteredPrograms
      for (let i = 0, len = programs.length; i < len; i++) {
        if (programs[i].id === id) {
          program = programs[i]
          break
        }
      }
      return program
    },
    resetForm () {
      const s = initialData(this.$store.getters.preSelectedProgramId)
      Object.keys(s).forEach(key => {
        this[key] = s[key]
      })
      this.form.access_control_id = Number(this.$getDecodedId(this.$route.params.patientId))
      this.$refs.scheduleMeasure.resetForm()
      this.$refs.cliniciansTeamsSelector.setValues({ clinicians: [], teams: [], teamsFromPrograms: [] })
      this.$validator.flag('program', { dirty: false })
      this.$validator.reset()
    },
    assignProgram (program) {
      const measuresAvailableInAdmission = this.filterAdmissionMeasures(program)
      this.form.program_id = program.id
      this.form.program_measures = measuresAvailableInAdmission.map(measure => {
        return {
          id: measure.measure_storage.id,
          measure_name: `${measure.measure_storage.abbreviation} ${measure.measure_storage.name}`,
          measure_abbr: measure.measure_storage.abbreviation,
          belongs_to_program: true,
          frequency: measure.frequency,
          day_of_week: measure.day_of_week,
          scheduling_type: measure.scheduling_type
        }
      })

      this.selectedClinicians = {
        clinicians: program.program_clinicians.map(obj => obj.clinician_id),
        teams: program.program_clinician_teams.map(ct => ct.clinician_team.id)
      }

      this.$refs.cliniciansTeamsSelector.setValues(this.selectedClinicians)
      this.$validator.flag('program', { dirty: true })
    },
    filterAdmissionMeasures (program) {
      const respondentTypesSettingName = this.patient.age >= 18 ? 'measure_respondent_type_adult' : 'measure_respondent_type_child'
      const availableRespondentsTypes = this.settings[respondentTypesSettingName]
      return program.measures.filter(measure => {
        return parseInt(measure.day_of_week) !== 101 && availableRespondentsTypes.includes(measure.measure_storage.respondent)
      })
    },
    enqueueMeasure (options) {
      const schedule = options.scheduleData
      const scheduleType = schedule && schedule.schedule_type && schedule.schedule_type === 'visit' ? 2 : 1
      this.customMeasuresEnqueued.push({
        id: schedule.measure_id,
        measure_name: schedule.measure_name,
        frequency: schedule.frequency,
        due_date: moment(schedule.due_date).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
        belongs_to_program: false,
        scheduling_type: scheduleType
      })
      this.$refs.scheduleMeasure.resetForm()
    },
    deleteEnqueued (measure) {
      // We need this logic to keep separeted measures added automagically from Programs
      // And measures added by the Clinician
      if (measure.belongs_to_program) {
        this.form.program_measures = this.form.program_measures.filter(obj => obj.id !== measure.id)
      } else {
        this.customMeasuresEnqueued = this.customMeasuresEnqueued.filter(obj => obj.id !== measure.id)
      }
    }
  }
}
</script>
<style scoped="scoped">
.add-patient-to-program-form {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
}
.add-patient-to-program-form .buttons {
  text-align: right;
  margin-top: 20px;
}

.add-patient-to-program-form .fa-search {
  position: absolute;
  width: 40px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  text-align: center;
  -webkit-transition: -webkit-transform .2s ease;
  transition: -webkit-transform .2s ease;
  transition: transform .2s ease;
  transition: transform .2s ease,
  -webkit-transform .2s ease;
  line-height: 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  color: #9b9b9b;
}
</style>
