import store from '@/store/index'

const usageTypeAdmissionName = 'admission'
const usageTypeMidpointName = 'midpoint'
const usageTypeDischargeName = 'discharge'
const usageTypeStandardName = 'standard'

/**
 * Get Usage type by string name.
 *
 * @param measureUsageTypes array
 * @param stringName string
 * @returns {{}|null}
 */
function getUsageTypeByStringName (measureUsageTypes, stringName) {
  return measureUsageTypes.find((i) => i.name === stringName)
}

/**
 * Create a object for mapping Usage Types.
 *
 * @param settings
 * @returns {{standard: ({}|null), discharge: ({}|null), midpoint: ({}|null), admission: ({}|null)}}
 */
function createUsageTypeMap (settings) {
  if (!settings || !settings.measure_usage_types || !settings.measure_usage_types.length) {
    throw new Error('Invalid Measure Usage Types settings received.')
  }

  return {
    admission: getUsageTypeByStringName(settings.measure_usage_types, usageTypeAdmissionName),
    midpoint: getUsageTypeByStringName(settings.measure_usage_types, usageTypeMidpointName),
    discharge: getUsageTypeByStringName(settings.measure_usage_types, usageTypeDischargeName),
    standard: getUsageTypeByStringName(settings.measure_usage_types, usageTypeStandardName)
  }
}

/**
 * Helpers top centralize custom Settings Accessors.
 */
export class UsageTypeSettingService {
  /**
   * Constructor.
   */
  constructor () {
    this.usageTypeMap = createUsageTypeMap(store.getters.generalSettings)
  }

  /**
   * Get Usage Type Id for the Admission usage type object stored within settings.
   * @returns int|null
   */
  getUsageTypeAdmission () {
    return this.usageTypeMap.admission
  }

  /**
   * Get Usage Type Id for the Midpoint usage type object stored within settings.
   * @returns int|null
   */
  getUsageTypeMidpoint () {
    return this.usageTypeMap.midpoint
  }

  /**
   * Get Usage Type Id for the Discharge usage type object stored within settings.
   * @returns int|null
   */
  getUsageTypeDischarge () {
    return this.usageTypeMap.discharge
  }

  /**
   * Get Usage Type for the Standard usage type object stored within settings.
   * @returns int|null
   */
  getUsageTypeStandard () {
    return this.usageTypeMap.standard
  }

  /**
   * Get Usage Type Map.
   * @returns {{standard: ({}|null), discharge: ({}|null), midpoint: ({}|null), admission: ({}|null)}}
   */
  getUsageTypeMap () {
    return this.usageTypeMap
  }
}
