<template>
  <multiselect
    :value="formatedValues.all"
    :options="options"
    :multiple="true"
    :preserve-search="currentBrowser.isExplorer"
    :disabled="isDisabled"
    :group-values="(teamsEnabled) ? 'items': null"
    :group-label="(teamsEnabled) ? 'collection': null"
    :placeholder="$t('select')"
    track-by="name"
    label="name"
    @input="onChange"
  >
    <template slot="noResult">
      {{ $t('noResultsFound') }}
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapGetters } from 'vuex'
import { GET_ACTIVE_CLINICIANS, GET_ALL_CLINICIANS_TEAMS } from '@/store/modules/clinicians/constants.js'

export default {
  name: 'CliniciansTeamsSelector',
  components: {
    Multiselect
  },
  props: {
    value: [Object], // eslint-disable-line
    teamsEnabled: {
      type: Boolean,
      default: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      formatedValues: {},
      originalValues: {}
    }
  },
  computed: {
    ...mapGetters({
      currentBrowser: 'getCurrentBrowser',
      clinicianTeams: 'getClinicianTeams',
      allClinicians: 'getClinicians'
    }),
    action () {
      return this.$route.params.programId ? 'update' : 'create'
    },
    clinicians () {
      return this.allClinicians.map(clinician => {
        return {
          id: clinician.id,
          name: clinician.name,
          type: 'clinician'
        }
      })
    },
    teams () {
      return this.clinicianTeams.map(team => {
        return {
          id: team.id,
          name: team.name,
          type: 'team'
        }
      })
    },
    options () {
      if (!this.teamsEnabled) {
        return this.clinicians
      } else {
        return [
          {
            collection: 'Clinicians',
            items: this.clinicians
          },
          {
            collection: 'Clinician Teams',
            items: this.teams
          }
        ]
      }
    }
  },
  created () {
    const teamsPromise = this.$store.dispatch(GET_ALL_CLINICIANS_TEAMS, { paginate: false })
    const cliniciansPromise = this.$store.dispatch(GET_ACTIVE_CLINICIANS)
    Promise.all([teamsPromise, cliniciansPromise]).finally(() => {
      this.setInitialValues(this.value)
    })
  },
  methods: {
    onChange (value, isInitialize) {
      isInitialize = isInitialize === true
      const clinicians = value.filter(obj => obj.type === 'clinician').map(obj => obj.id)
      const teams = value.filter(obj => obj.type === 'team').map(obj => obj.id)
      const teamsFromPrograms = value.filter(obj => obj.type === 'teamFromProgram').map(obj => obj.id)

      this.formatedValues = {
        clinicians,
        teamsFromPrograms,
        teams,
        all: value
      }

      if (isInitialize) {
        this.originalValues = { ...this.formatedValues }
        return
      }

      if (!value.length) {
        return this.$emit('input', undefined)
      }

      this.$emit('input', this.formatedValues)
    },
    setInitialValues (value) {
      const result = []

      const clinicians = value.clinicians || []
      if (clinicians && clinicians.length) {
        clinicians.forEach(id => {
          const match = this.clinicians.find(obj => parseInt(obj.id) === parseInt(id))
          if (match) {
            result.push(match)
          }
        })
      }

      const teams = value.teams || []
      if (teams && teams.length) {
        teams.forEach(id => {
          const match = this.teams.find(obj => parseInt(obj.id) === parseInt(id))
          if (match) {
            result.push(match)
          }
        })
      }

      const teamsFromPrograms = value.teamsFromPrograms || []
      if (teamsFromPrograms && teamsFromPrograms.length) {
        teamsFromPrograms.forEach(id => {
          const match = this.teams.find(obj => parseInt(obj.id) === parseInt(id))
          match.type = 'teamFromProgram'
          if (match) {
            result.push(match)
          }
        })
      }
      this.onChange(result, true)
    },
    resetValues () {
      this.formatedValues = { ...this.originalValues }
      this.$emit('input', this.formatedValues)
    },
    commitValues () {
      this.originalValues = { ...this.formatedValues }
    },
    setValues (values) {
      this.setInitialValues(values)
    }
  }
}
</script>
